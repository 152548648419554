import HttpRequest from "./http_request";
import { api_url } from "../config";
const prefix = "/news";

class NewsService extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async all_news() {
    const { data } = await this.get(prefix+'');
    
    return data? data:false
  }

  async get_news(id) {
    const { data } = await this.get(prefix+'/post/'+id);

    return data? data:false;
  }
}

export default NewsService;
